<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9.99936L19 9.99936"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9.99976 0.999634L9.99976 18.9996"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: {
    color: {
      type: String,
      default: '#39434B',
    },
  },
};
</script>

<style scoped></style>
