import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loaderStore', {
  state: () => ({
    is_open: false,
  }),
  actions: {
    toggle() {
      this.is_open = !this.is_open;
      setTimeout(() => {
        this.is_open = false;
      }, 800);
    },
  },
});
