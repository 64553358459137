<template>
  <div class="selectable-list-wrapper">
    <div class="selectable-list">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="selectable-list-item"
        @click.stop="onSelect(item.value)"
      >
        <span
          class="selectable-list-item-label"
          :class="{ active: item.value === value }"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectableList',
  props: {
    items: Array,
    value: String,
  },
  emits: ['update:value'],
  methods: {
    onSelect(value) {
      this.$emit('update:value', value);
    },
  },
};
</script>

<style scoped lang="scss">
.selectable-list-wrapper {
  .selectable-list {
    display: flex;
    flex-direction: column;
    &-item {
      cursor: pointer;
      padding: 16px 0;
      border-bottom: 1px solid #a4a6b5;
      &-label {
        color: #222e37;
        font-family: Raleway, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        opacity: 0.7;
        padding-left: 17px;
        position: relative;
        &:hover {
          color: #394650;
          opacity: 1;
        }
        &.active {
          opacity: 1;
          font-weight: 600;
          color: #0a7cff;
          &::after {
            content: '';
            display: block;
            width: 3px;
            height: 23px;
            position: absolute;
            background-color: #0a7cff;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}
</style>
