import axios from 'axios';
import { useLangStore } from '@/app/store/langStore';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
  baseURL: 'https://dev.api.dive.tpu.ru/api',
};

const axiosInstance = axios.create(defaultOptions);
axiosInstance.interceptors.request.use(function (config) {
  add_token(config);
  add_locale(config);
  return config;
});

const add_token = (config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
};

const add_locale = (config) => {
  if (config.method.toLowerCase() === 'get') {
    config.params = {
      ...config.params,
      lang: useLangStore().getLang(),
    };
  }
};

export default axiosInstance;
