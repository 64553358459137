<script>
export default {
  name: 'DiveTag',
  props: {
    text: {
      type: String,
      default: 'Текст',
    },
  },
};
</script>

<template>
  <div class="dive-tag">{{ text }}</div>
</template>

<style scoped lang="scss">
.dive-tag {
  padding: 2px 12px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: #fff;
  border: 1px solid #a4a6b5;
  border-radius: 100px;
}
</style>
