<template>
  <div class="section-title-wrapper">
    <h2 class="section-subtitle">
      {{ subtitle }}
    </h2>
    <h1 class="section-title">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style scoped lang="scss">
.section-title-wrapper {
  .section-subtitle {
    color: #0a7cff;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    left: 0;
  }

  h1 {
    padding-top: 4px;
  }
  h2 {
    padding-bottom: 4px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
}
</style>
