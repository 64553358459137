import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useMethodistsStore = defineStore('methodistsStore', {
  state: () => ({
    methodists: [],
    methodist: null,
    loading: false,
  }),
  actions: {
    async get_methodists(reset = false) {
      if (this.methodists.length && !reset) {
        return this.methodists;
      }
      this.loading = true;
      this.methodists = await axiosInstance
        .get('/lms/advisors')
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset() {
      this.get_methodists(true);
      if (this.methodist) {
        this.get_methodist(this.methodist.id);
      }
    },
    async get_methodist(id) {
      this.loading = true;
      this.methodist = await axiosInstance
        .get(`/lms/advisor/${id}`)
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset_methodist() {
      this.methodist = null;
    },
  },
});
