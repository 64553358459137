<template>
  <Teleport to="body">
    <Transition name="slide">
      <div v-show="is_open" class="loader-wrapper">
        <DiveLoader class="absolute-loader" />
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import DiveLoader from '@/shared/ui/DiveLoader.vue';
import { mapState } from 'pinia';
import { useLoaderStore } from '@/app/store/loaderStore';

export default {
  name: 'PageLoader',
  components: { DiveLoader },
  computed: {
    ...mapState(useLoaderStore, ['is_open']),
  },
};
</script>

<style scoped lang="scss">
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 10000;
}

.slide-enter-active {
  transition: all 0.2s ease-in-out;
}
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from {
  transform: translateY(100vh);
  opacity: 0;
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-100vh);
}
</style>
