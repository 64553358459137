import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useSliderStore = defineStore('sliderStore', {
  state: () => ({
    slides: [],
    loading: false,
  }),
  actions: {
    async getSlides(reset = false) {
      if (this.slides.length && !reset) {
        return this.slides;
      }
      this.loading = true;
      this.slides = await axiosInstance
        .get('/lms/slider')
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset() {
      this.getSlides(true);
    },
  },
});
