import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useTagsStore = defineStore('tagsStore', {
  state: () => ({
    tags: [],
    loading: false,
  }),
  actions: {
    async getTags(reset = false) {
      if (this.tags.length && !reset) {
        return this.tags;
      }
      this.loading = true;
      this.tags = await axiosInstance
        .get('/lms/categories')
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset() {
      this.getTags(true);
    },
  },
});
