<template>
  <div
    class="hamburger-button"
    :class="{ active, isTransparent }"
    @click="toggleOpen"
  >
    <button type="button" class="burger-button" title="Menu">
      <span class="burger-bar burger-bar--1"></span>
      <span class="burger-bar burger-bar--2"></span>
      <span class="burger-bar burger-bar--3"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'HamburgerComponent',
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['toggle-menu'],
  methods: {
    toggleOpen() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style lang="scss" scoped>
$burger-color: #444;
$primary: #2b2b68;
.hidden {
  visibility: hidden;
}
button {
  cursor: pointer;
}
/* remove blue outline */
button:focus {
  outline: 0;
}
.burger-button {
  position: relative;
  height: 30px;
  width: 40px;
  display: block;
  z-index: 99;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.burger-bar {
  background-color: $burger-color;
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 3px;
  width: auto;
  margin-top: -1px;
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
  top: 40%;
}
.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(1);
}
.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}
.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}
.burger-bar--3 {
  transform: translateY(6px);
  top: 60%;
}
.hamburger-button.isTransparent .burger-bar {
  background-color: #fff;
}
#burger.active .burger-button {
  transform: rotate(-180deg);
}
#burger.active .burger-bar {
  background-color: lighten($primary, 10);
}
#burger.active .burger-bar--1 {
  transform: rotate(45deg);
  top: 50%;
}
#burger.active .burger-bar--2 {
  opacity: 0;
}
#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
  top: 50%;
}
@media screen and (max-width: 991px) {
  #burger {
    display: block;
  }
}
@media screen and (min-width: 990px) {
  #burger {
    display: none;
  }
}
</style>
