import { defineStore } from 'pinia';
import { useSliderStore } from '@/app/store/sliderStore';
import { usePopularApplicationStore } from '@/app/store/popularApplicationStore';
import { useTagsStore } from '@/app/store/tagsStore';
import { useApplicationsStore } from '@/app/store/applicationsStore';
import { useMethodistsStore } from '@/app/store/methodistsStore';
import { useFAQsStore } from '@/app/store/FAQStore';
import { useFooterMenuStore } from '@/app/store/footerMenuStore';

export const useLangStore = defineStore('langStore', {
  state: () => ({
    lang: 'ru',
    initial_render: false,
  }),
  actions: {
    setLang(lang) {
      if (!this.initial_render) {
        this.initial_render = true;
        this.lang = lang;
        return;
      }
      if (lang !== this.getLang()) {
        useSliderStore().reset();
        usePopularApplicationStore().reset();
        useTagsStore().reset();
        useApplicationsStore().reset();
        useMethodistsStore().reset();
        useFAQsStore().reset();
        useFooterMenuStore().reset();
        this.lang = lang;
      }
    },
    getLang() {
      return this.lang;
    },
  },
});
