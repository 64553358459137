<template>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom__copyrights">
        <div class="footer-bottom__copyright">
          &copy; D.I.V.E., 2022-{{ year }}
        </div>
      </div>
      <div class="footer-bottom__links-privacy">
        <a v-for="item in items" :key="item.id" href="#" class="footer__link">{{
          item.title
        }}</a>
        <router-link
          :to="{ name: 'FAQView', params: { locale: $route.params.locale } }"
          class="footer__link"
          >{{ $t('menu.FAQ') }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useFooterMenuStore } from '@/app/store/footerMenuStore';

export default {
  name: 'FooterRightsComponent',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(useFooterMenuStore, ['items', 'loading']),
  },
  mounted() {
    this.getItems();
  },
  methods: {
    ...mapActions(useFooterMenuStore, ['getItems']),
  },
};
</script>

<style lang="scss" scoped>
.footer-bottom {
  font-family: 'Raleway', sans-serif;
  background: #181818;
  display: flex;
  border-top: 1px solid rgba(164, 166, 181, 1);

  width: 100%;
  margin: auto;
  overflow: hidden;
  .container {
    flex-direction: row;
    @media screen and (max-width: 500px) {
      padding: 0 10px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__copyrights {
    padding-top: 24px;
    padding-bottom: 32px;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    mix-blend-mode: normal;
    @media screen and (max-width: 426px) {
      padding-bottom: 0;
    }
  }
  &__links-privacy {
    padding-top: 27px;
    padding-bottom: 34px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    flex-wrap: wrap;
    @media screen and (max-width: 426px) {
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
    }
    a {
      margin-left: 32px;
      text-decoration: underline;
    }
  }
}
</style>
