<template>
  <div class="social-networks">
    <a
      v-for="item in items"
      :key="item.id"
      :href="item.link"
      class="social__link"
      :class="getClassname(item.social_network)"
      target="_blank"
    />
    <!--    <a-->
    <!--      href="https://t.me/vrdive"-->
    <!--      class="social__link"-->
    <!--      :class="{-->
    <!--        'telegram-icon': type === 'filled',-->
    <!--        'telegram-icon-lines': type === 'outlined',-->
    <!--        'telegram-icon-monocolor': type === 'monocolor',-->
    <!--      }"-->
    <!--      target="_blank"-->
    <!--    ></a>-->
    <!--    <a-->
    <!--      href="https://www.youtube.com/@VR_D.I.V.E./featured"-->
    <!--      class="social__link"-->
    <!--      :class="{-->
    <!--        'youtube-icon': type === 'filled',-->
    <!--        'youtube-icon-lines': type === 'outlined',-->
    <!--        'youtube-icon-monocolor': type === 'monocolor',-->
    <!--      }"-->
    <!--      target="_blank"-->
    <!--    ></a>-->
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useSocialsStore } from '@/app/store/socialsStore';

export default {
  name: 'SocialNetworks',
  props: {
    type: {
      type: String,
      default: 'filled',
    },
  },
  computed: {
    ...mapState(useSocialsStore, ['items', 'loading']),
  },
  methods: {
    getClassname(name) {
      const base = `${name.trim()}-icon`;
      switch (this.type) {
        case 'filled':
          return `${base}`;
        case 'outlined':
          return `${base}-lines`;
        case 'monocolor':
          return `${base}-monocolor`;
        default:
          return `${base}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.social-networks {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
</style>
