import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useFooterMenuStore = defineStore('footerMenuStore', {
  state: () => ({
    items: [],
    loading: false,
  }),
  actions: {
    async getItems(reset = false) {
      if (this.items.length && !reset) {
        return this.items;
      }
      this.loading = true;
      this.items = await axiosInstance
        .get('/lms/content/bottom-menu')
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset() {
      this.getItems(true);
    },
  },
});
