import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/pages/HomeView.vue';
import {
  loadLocaleMessages,
  setI18nLanguage,
  SUPPORT_LOCALES,
} from '@/shared/config/i18n';
import { useLangStore } from '@/app/store/langStore';
import { useLoaderStore } from '@/app/store/loaderStore';
import { to_top_window } from '@/shared/lib/window_navigation';

export function setupRouter(i18n) {
  const locale =
    i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value;

  //setup routes
  const routes = [
    {
      path: '/:locale',
      name: 'HomeView',
      component: HomeView,
      meta: {
        layout: 'landing-layout',
        title: 'D.I.V.E. - платформа VR-образования',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/simulators/:hardcategory?',
      name: 'SimulatorsView',
      component: () => import('../pages/SimulatorsView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Тренажеры',
        isPrivate: false,
        props: true,
      },
    },
    {
      path: '/:locale/simulators/:id',
      name: 'SimulatorCaseView',
      component: () => import('../pages/SimulatorCaseView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Тренажеры',
        isPrivate: false,
      },
    },
    // {
    //   path: '/:locale/simulators',
    //   name: 'PrivateAccountView',
    //   component: () => import('../pages/SimulatorCaseView.vue'),
    //   meta: {
    //     layout: 'landing-layout',
    //     title: 'Тренажеры',
    //     isPrivate: false,
    //   },
    // },
    {
      path: '/:locale/service',
      name: 'ServicesView',
      component: () => import('../pages/ServicesView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Тренажеры',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/methodists',
      name: 'MethodistsView',
      component: () => import('../pages/MethodistsView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Методисты',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/methodists/:id',
      name: 'MethodistView',
      component: () => import('../pages/MethodistView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Методист',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/contacts',
      name: 'ContactsView',
      component: () => import('../pages/ContactView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Наши контакты',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/developers',
      name: 'DevelopersView',
      component: () => import('../pages/SimulatorCaseView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Разработчикам',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/terms',
      name: 'TermsView',
      component: () => import('../pages/TermsView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Пользовательское соглашение',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/privacy',
      name: 'PrivacyView',
      component: () => import('../pages/PolicyView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Политика конфиденциальности',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/cookies',
      name: 'CookiesView',
      component: () => import('../pages/CookiesView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Как мы используем cookie',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/help',
      name: 'HelpView',
      component: () => import('../pages/HelpView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Справка',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/faq',
      name: 'FAQView',
      component: () => import('../pages/FAQView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Часто задаваемые вопросы',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/support',
      name: 'SupportView',
      component: () => import('../pages/SupportView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Поддержка',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/login',
      name: 'LoginView',
      component: () => import('../pages/LoginView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Вход',
        isPrivate: false,
      },
    },
    {
      path: '/:locale/registrationOnRequest',
      name: 'RegistrationOnRequestView',
      component: () => import('../pages/RegistrationOnRequestView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Регистрация',
        isPrivate: false,
      },
    },
    // временно отключено ------------------------------------------>
    // {
    //   path: '/:locale/account',
    //   name: 'AccountView',
    //   component: () => import('../pages/AccountView.vue'),
    //   meta: {
    //     layout: 'landing-layout',
    //     title: 'Личный кабинет',
    //     isPrivate: false,
    //   },
    // },
    // временно отключено ------------------------------------------>
    // {
    //   path: '/:locale/userContent',
    //   name: 'UserContentView',
    //   component: () => import('../pages/UserContentView.vue'),
    //   meta: {
    //     layout: 'landing-layout',
    //     title: 'Личный кабинет пользователя',
    //     isPrivate: false,
    //   },
    // },
    {
      path: '/:locale/launcher',
      name: 'LauncherView',
      component: () => import('../pages/LauncherView.vue'),
      meta: {
        layout: 'landing-layout',
        title: 'Лаунчер',
        isPrivate: false,
      },
    },
    // временно отключено ------------------------------------------>
    // {
    //   path: '/:locale/adminAccount',
    //   name: 'AdministratorAccountView',
    //   component: () => import('../pages/AdministratorAccountView.vue'),
    //   meta: {
    //     layout: 'landing-layout',
    //     title: 'Аккаунт администратора',
    //     isPrivate: false,
    //   },
    // },
    {
      path: '/',
      redirect: () => `/${locale}`,
    },
    {
      path: '/:/pathMatch(.*)*',
      redirect: () => `/${locale}`,
    },
  ];
  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  // navigation guards
  router.beforeEach(async (to, from, next) => {
    useLoaderStore().toggle();
    const paramsLocale = to.params.locale;
    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
      return next(`/${locale}`);
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale);
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale);
    useLangStore().setLang(paramsLocale);
    /*
        if (to.matched.some(record => record.meta.isPrivate)) {
            next('credential')
        } else {
            next()
        }
         */
    to_top_window();
    next();
  });
  return router;
}
