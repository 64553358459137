import { createApp } from 'vue';
import App from './app/App.vue';

import { setupRouter } from '@/app/router';

import { setupI18n } from '@/shared/config/i18n';
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';
import zh from '@/locales/zh.json';
import ToastPlugin from 'vue-toast-notification';
//Подключим файлы компонентов
import LandingLayout from '@/shared/ui/layout/ui/LandingLayout.vue';
// Подключим стили
import '@/assets/scss/dive.scss';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { createPinia } from 'pinia';
const pinia = createPinia();

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'en',
  messages: {
    en,
    ru,
    zh,
  },
});

const router = setupRouter(i18n);

createApp(App)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(ToastPlugin, {
    position: 'top-right',
    duration: 5000,
  })
  .component('landing-layout', LandingLayout)
  .mount('#app');
