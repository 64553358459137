<script>
export default {
  name: 'LauncherWidgetComponent',
  components: {},
};
</script>
<template>
  <div class="launcher__container">
    <div class="launcher-widget__container">
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/oculus.png"
        alt="Очки виртуальной реальности Oculus"
        class="vr-element vr-1"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/left-joystick.png"
        alt="Левый джойстик виртуальных очков"
        class="vr-element vr-2"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/right-joystick.png"
        alt="Правый джойстик виртуальных очков"
        class="vr-element vr-3"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/pico.png"
        alt="Очки виртуальной реальности Pico"
        class="vr-element vr-4"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/vive.png"
        alt="Очки виртуальной реальности Vive"
        class="vr-element vr-5"
      />
      <div class="info-title">
        {{ $t('launcher-widget.title.1') }}<br />
        {{ $t('launcher-widget.title.2') }}<br />
        {{ $t('launcher-widget.title.3') }}
      </div>
      <div class="link-block">
        <p class="link-label">
          {{ $t('launcher-widget.subtitle') }}
        </p>
        <div class="link-button">
          <router-link
            :to="{
              name: 'LauncherView',
            }"
            class="btn btn-full"
            >{{ $t('launcher-widget.launcher-button') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.launcher__container {
  margin: 85px 0 50px 0;
  overflow: hidden;
}

.launcher-widget__container {
  flex-direction: column;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(16, 114px);
  // grid-template-rows: repeat(10, 114px);
  grid-auto-rows: 114px;
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .vr-1 {
    grid-column: 1 / 9;
    grid-row: 1 / 7;
  }

  .vr-2 {
    grid-column: 1 / 4;
    grid-row: 6 / 8;
  }

  .vr-3 {
    grid-column: 14 / 17;
    grid-row: 4 / 7;
  }

  .vr-4 {
    grid-column: 13 / 17;
    grid-row: 1 / 3;
  }

  .vr-5 {
    grid-column: 9 / 13;
    grid-row: 2 / 5;
  }
  .info-title {
    grid-column: 4 / 11;
    grid-row: 5 / 8;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 900;
    font-size: 84px;
    line-height: 100%;
    letter-spacing: 3px;
    align-self: center;
    color: #222e37;
  }

  .link-block {
    grid-column: 9 / 13;
    grid-row: 6 / 8;
  }

  .link-label {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    line-height: 120%;
    font-size: 22px;
    color: #222e37b3;
    margin-bottom: 50px;
    margin-top: 40px;
  }
  @media screen and (max-width: 1850px) {
    grid-template-columns: repeat(14, 100px);
    .info-title {
      font-size: 72px;
    }
    .link-label {
      font-size: 20px;
    }
    .vr-1 {
      grid-column: 1 / 9;
      grid-row: 1 / 6;
    }

    .vr-2 {
      grid-column: 1 / 4;
      grid-row: 5 / 7;
    }

    .vr-3 {
      grid-column: 13 / 15;
      grid-row: 5 / 7;
    }

    .vr-4 {
      grid-column: 11 / 15;
      grid-row: 1 / 3;
    }

    .vr-5 {
      grid-column: 8 / 12;
      grid-row: 3 / 5;
    }
  }
  @media screen and (max-width: 1430px) {
    grid-template-columns: repeat(16, 90px);
    grid-auto-rows: 90px;
    .info-title {
      grid-column: 5 / 12;
      font-size: 64px;
    }

    .link-block {
      grid-column: 10 / 14;
    }

    .link-label {
      font-size: 20px;
    }
    .vr-1 {
      grid-column: 3 / 9;
      grid-row: 1 / 6;
    }

    .vr-2 {
      grid-column: 2 / 4;
      grid-row: 5 / 7;
    }

    .vr-3 {
      grid-column: 14 / 16;
      grid-row: 6 / 8;
    }

    .vr-4 {
      grid-column: 12 / 16;
      grid-row: 1 / 3;
    }

    .vr-5 {
      grid-column: 9 / 13;
      grid-row: 3 / 5;
    }
  }
  @media screen and (max-width: 1140px) {
    grid-template-columns: repeat(11, 100px);
    .info-title {
      grid-column: 3 / 10;
      font-size: 52px;
    }

    .link-block {
      grid-column: 7 / 10;
    }

    .link-label {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .vr-1 {
      grid-column: 1 / 6;
      grid-row: 1 / 6;
    }

    .vr-2 {
      grid-column: 1 / 3;
      grid-row: 5 / 7;
    }

    .vr-3 {
      grid-column: 10 / 12;
      grid-row: 6 / 8;
    }

    .vr-4 {
      grid-column: 8 / 12;
      grid-row: 1 / 4;
    }

    .vr-5 {
      grid-column: 6 / 9;
      grid-row: 3 / 6;
    }
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(9, 100px);
    .info-title {
      grid-column: 2 / 8;
      grid-row: 4 / 6;
      font-size: 48px;
    }

    .link-block {
      grid-column: 5 / 9;
      grid-row: 5 / 7;
    }

    .link-label {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .vr-1 {
      grid-column: 1 / 6;
      grid-row: 1 / 5;
      padding: 0 0 0 50px;
    }

    .vr-2 {
      grid-column: 6 / 8;
      grid-row: 1 / 3;
    }

    .vr-3 {
      grid-column: 8 / 10;
      grid-row: 3 / 5;
    }

    .vr-4 {
      display: none;
    }

    .vr-5 {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(9, 80px);
    grid-auto-rows: 80px;
    .link-label {
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 573px) {
    grid-template-columns: repeat(7, 80px);
    grid-auto-rows: 80px;
    .info-title {
      grid-column: 2 / 5;
      grid-row: 2 / 4;
      font-size: 40px;
      padding: 0;
    }

    .link-block {
      grid-column: 2 / 5;
      grid-row: 4 / 6;
    }

    .link-label {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .vr-1 {
      grid-column: 5 / 8;
      grid-row: 1 / 4;
      padding-left: 10px;
    }

    .vr-2 {
      grid-column: 5 / 6;
      grid-row: 5 / 6;
    }

    .vr-3 {
      grid-column: 6 / 7;
      grid-row: 3 / 5;
    }
  }
  @media screen and (max-width: 445px) {
    grid-template-columns: repeat(7, 80px);
    grid-auto-rows: 80px;
    .info-title {
      grid-column: 3 / 6;
      grid-row: 2 / 4;
      font-size: 32px;
      padding: 0;
    }

    .link-block {
      grid-column: 3 / 6;
      grid-row: 4 / 6;
    }

    .link-label {
      font-size: 14px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .vr-1 {
      grid-column: 5 / 8;
      grid-row: 1 / 4;
      padding-left: 10px;
    }

    .vr-2 {
      grid-column: 2 / 3;
      grid-row: 4 / 5;
    }

    .vr-3 {
      grid-column: 6 / 7;
      grid-row: 3 / 5;
    }
    .vr-5 {
      display: block;
      grid-column: 2 / 4;
      grid-row: 1 / 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-self: center;
    justify-self: center;
  }
}

// .launcher-widget__container {
//   position: relative;
//   display: flex;
//   width: 100%;
//   min-height: 100%;
//   overflow: hidden;
// }

// .vr-element {
//   position: absolute;

// .info__container {
//   grid-column: 3 / 9;
//   grid-row: 4 / 6;
//   display: grid;
//   grid-template-columns: 2fr 1fr 1fr 1fr;
//   grid-auto-rows: auto;
//   width: 100%;
//   max-width: 1140px;
//   margin-top: auto;
//   margin-left: auto;
//   margin-right: auto;
// }

.link-button {
}
</style>
