<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="video" class="overlay-background" @click="closeOverlay">
        <div class="overlay-content">
          <DiveLoader v-if="loading" class="absolute-loader" />
          <iframe
            v-show="!loading"
            :src="video"
            allowfullscreen
            width="100%"
            height="100%"
            @load="onLoad"
          ></iframe>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import DiveLoader from '@/shared/ui/DiveLoader.vue';

export default {
  name: 'VideoOverlay',
  components: { DiveLoader },
  props: {
    video: {
      type: String,
      default: null,
    },
  },
  emits: ['set_video'],
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    onLoad() {
      this.loading = false;
    },
    closeOverlay() {
      this.$emit('set_video', null);
      this.loading = true;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-content {
  position: relative;
  width: 80%; /* Ширина контента оверлея */
  height: 80%; /* Высота контента оверлея */
  max-width: 960px; /* Максимальная ширина контента оверлея */
  max-height: 540px; /* Максимальная высота контента оверлея */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
