import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useFAQsStore = defineStore('FAQsStore', {
  state: () => ({
    FAQs: [],
    loading: false,
  }),
  actions: {
    async getFAQs(reset = false) {
      if (this.FAQs.length && !reset) {
        return this.FAQs;
      }
      this.loading = true;
      this.FAQs = await axiosInstance
        .get('/lms/faq')
        .then((res) => res.data.data);
      this.loading = false;
    },
    reset() {
      this.getFAQs(true);
    },
  },
});
