import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const useApplicationsStore = defineStore('applicationsStore', {
  state: () => ({
    applications: [],
    loading: false,
    page: 1,
    limit: 10,
    pages: 1,
    category_id: null,
  }),
  actions: {
    async getApplications(reset = false) {
      if (this.applications.length && !reset) {
        return this.applications;
      }

      const params = {
        page: this.page,
      };
      this.category_id && (params.category_id = this.category_id);

      this.loading = true;
      const response = await axiosInstance
        .get('/lms/applications', { params })
        .then((res) => res.data.data);
      this.applications = response.items;
      this.limit = response.limit;
      this.pages = Math.ceil(response.total_count / response.limit);
      this.loading = false;
    },

    setPage(page) {
      this.page = page;
      this.getApplications(true);
    },
    setCategory(category_id) {
      this.category_id = category_id;
      this.page = 1;
      this.getApplications(true);
    },
    reset() {
      this.getApplications(true);
    },
    reset_parameters() {
      this.page = 1;
      this.category_id = null;
    },
  },
});
