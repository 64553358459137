<template>
  <agile
    v-if="!loading"
    ref="carousel"
    :options="sliderOptions"
    :nav-buttons="false"
    :dots="false"
  >
    <MainSlide
      v-for="(slide, index) in slides"
      :key="index"
      :total="slides.length"
      :index="index"
      :data="slide"
      @set_video="video = $event"
      @to_next_slide="to_next_slide"
      @to_prev_slide="to_prev_slide"
    />
  </agile>
  <div v-else class="loader">
    <DiveLoader class="absolute-loader" />
  </div>
  <VideoOverlay :video="video" @set_video="video = $event" />
</template>

<script>
import { VueAgile } from 'vue-agile';
import MainSlide from '@/widgets/slider-on-main/ui/MainSlide.vue';
import { mapActions, mapState } from 'pinia';
import { useSliderStore } from '@/app/store/sliderStore';
import VideoOverlay from '@/shared/VideoOverlay.vue';
import DiveLoader from '@/shared/ui/DiveLoader.vue';

export default {
  name: 'SliderOnMainComponent',
  components: {
    DiveLoader,
    VideoOverlay,
    MainSlide,
    agile: VueAgile,
  },
  data() {
    return {
      sliderOptions: {
        navButtons: true,
      },
      video: null,
    };
  },
  computed: {
    ...mapState(useSliderStore, ['slides', 'loading']),
  },
  mounted() {
    this.getSlides();
  },
  methods: {
    ...mapActions(useSliderStore, ['getSlides']),
    to_next_slide() {
      this.$refs.carousel.goToNext();
    },
    to_prev_slide() {
      this.$refs.carousel.goToPrev();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/slider.scss';
.agile--no-nav-buttons .agile__actions {
  display: none !important; //TODO: write custom style for arrows
}
.loader {
  background: rgba(0, 0, 0, 0.6) no-repeat center;
  position: relative;
  height: 100vh;
  width: 100%;
}
</style>
