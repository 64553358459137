import { defineStore } from 'pinia';
import axiosInstance from '@/app/axios';

export const usePopularApplicationStore = defineStore(
  'popularApplicationStore',
  {
    state: () => ({
      applications: [],
      loading: false,
    }),
    actions: {
      async getApplications(reset = false) {
        if (this.applications.length && !reset) {
          return this.applications;
        }
        this.loading = true;
        this.applications = await axiosInstance
          .get('/lms/popular-application')
          .then((res) => res.data.data);
        this.loading = false;
      },
      reset() {
        this.getApplications(true);
      },
    },
  },
);
