<template>
  <div class="collapse-text-card-wrapper">
    <div
      class="collapse-text-card"
      :class="{ active: isOpen || open }"
      @click="toggle"
    >
      <div class="collapse-text-card__header">
        <h3 class="collapse-text-card__title">
          {{ title }}
        </h3>
        <PlusIcon />
      </div>
      <Transition name="slide-fade">
        <div
          v-show="isOpen || open"
          class="collapse-text-card__body"
          v-html="description"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import PlusIcon from '@/assets/images/icons/PlusIcon.vue';

export default {
  name: 'CollapseTextCard',
  components: { PlusIcon },
  props: {
    title: String,
    description: String,
    index: Number,
    manual: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      if (this.manual) {
        this.$emit('click');
        return;
      }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.collapse-text-card-wrapper {
  .collapse-text-card {
    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-transform: uppercase;
      font-family: Proxima Nova;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      padding: 20px;

      border-radius: 10px;
      background-color: #f0f1f5;
      color: #222e37;
      cursor: pointer;
      position: relative;
      z-index: 30;
      svg {
        transition: all 0.2s ease;
      }

      &:hover {
        background-color: #f7f6fb;
        color: #0a7cff;
        svg {
          filter: invert(33%) sepia(93%) saturate(2879%) hue-rotate(202deg)
            brightness(104%) contrast(103%);
        }
      }
    }

    &__body {
      padding: 20px;
      border-top: 1px solid rgba(164, 166, 181, 0.98);
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      color: rgba(34, 46, 55, 0.7);
      font-weight: 500;
      line-height: 22px;
      border-radius: 0 0 10px 10px;
      background-color: #f0f1f5;
      cursor: pointer;
      position: relative;
      z-index: 23;
    }

    &.active {
      svg {
        transform: rotate(-45deg);
      }
      .collapse-text-card__header {
        border-radius: 10px 10px 0 0;
        color: #0a7cff;
      }
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>
